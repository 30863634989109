<template>
  <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: v$.inboxName.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL') }}
        <input v-model.trim="inboxName" type="text" :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
          @blur="v$.inboxName.$touch" />
        <span v-if="v$.inboxName.$error" class="message">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR') }}
        </span>
      </label>
    </div>

    <div class="w-full" style="margin-top: 20px;">
      <woot-submit-button :loading="uiFlags.isCreating" :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')" />
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables';
import { required } from '@vuelidate/validators';
import router from '../../../../index';
import getUuid from 'widget/helpers/uuid';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      inboxName: '',
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags', currentAccountId: 'getCurrentAccountId' }),
  },
  validations: {
    inboxName: { required },
  },
  methods: {
    async createChannel() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      try {
        const evolutionURL = window.globalConfig.EVOLUTION_API_URL

        const uuid = getUuid()

        const instanceName = `Conta-${this.currentAccountId}-${uuid}`

        const webhookUrl = new URL(`chatwoot/webhook/${instanceName}`, evolutionURL).toString()
        
        const apiChannel = await this.$store.dispatch('inboxes/createChannel', {
          name: this.inboxName,
          channel: {
            type: 'api',
            webhook_url: webhookUrl,
            provider: 'whatsapp_non_official',
            instance_name: instanceName
          },
        });

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: apiChannel.id,
          },
        });
      } catch (error) {
        useAlert(
          this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE') +
          '\n detail:' +
          error
        );
      }
    },
  },
};
</script>
